/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */


let helpLinks = {
	accounts: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1050673174/03-2-1+-+Accounts',
	groups: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1050804313/03-2-3+-+Groups',
	roles: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1050804332',
	rbac: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/566689849/RBAC',
	tenants: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/566853651/Multitenancy',
	throttling: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/566853634/Throttling',
	users: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1050804298/03-2-2+-+Users',
	
	registry: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/567771186/Registry',
	custom: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/567771268/Custom',
	arw: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1292697632/Automated+Workflows',
	clusters: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/567771180',
	socialPlatforms: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/1257046019',
	
	gwInstall: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/564690945',
	gwLocal: 'https://corsairm360.atlassian.net/wiki/spaces/Docs/pages/2012905473'
	
};

module.exports = helpLinks;