/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default {
	namespaced: true,
	
	state: {
		loaded: false,
		token: null,
		user: null,
		acl: null
	},
	
	mutations: {
		
		//set or clear user
		setUser(state, value) {
			if (value && typeof value === 'object') {
				state.user = clone(value.user);
				localStorage.setItem('user', JSON.stringify(state.user));
			}
		},
		
		clearUser(state) {
			state.user = null;
			localStorage.removeItem('user');
			localStorage.removeItem('project');
		},
		
		//set or clear acl
		setACL(state, value) {
			if (value && typeof value === 'object') {
				const newACL = (value.acl && value.acl.schema) ? clone(value.acl.schema) : null;
				
				if (!state.acl) state.acl = {};
				if (!value.env) {
					state.acl['portal'] = newACL;
				} else {
					state.acl[value.env] = newACL;
				}
				localStorage.setItem('acl', JSON.stringify(state.acl));
			}
		},
		
		removeENVACL(state, value) {
			if (state.acl && state.acl[value.toLowerCase()]) {
				delete state.acl[value.toLowerCase()];
			}
			localStorage.setItem('acl', JSON.stringify(state.acl));
		},
		
		clearACL(state) {
			state.acl = null;
			localStorage.removeItem('acl');
		},
		
		//set or clear tokens
		setToken(state, value) {
			if (value) {
				if (typeof value !== 'object') {
					console.error(`Could not save invalid token object: ${JSON.stringify(value)}`);
					return;
				}
				state.token = clone(value);
				localStorage.setItem('token', JSON.stringify(state.token));
			} else {
				state.token = null;
				localStorage.removeItem('token');
			}
		},
		
		reloadState(state) {
			state.token = JSON.parse(localStorage.getItem('token'));
			
			function loadCookie() {
				//check the cookies
				let cookies = document.cookie.split(";");
				if (cookies && cookies.length > 1) {
					let tempToken = {expires_in: 7200, token_type: 'bearer'};
					cookies.forEach((oneCookie) => {
						if (oneCookie.includes('access_token')) {
							tempToken.access_token = oneCookie.replace('access_token=', '').trim();
						} else if (oneCookie.includes('refresh_token')) {
							tempToken.refresh_token = oneCookie.replace('refresh_token=', '').trim();
						}
					});
					
					if (tempToken.access_token && tempToken.refresh_token) {
						// clear the old acl if exists
						state.acl = null;
						localStorage.removeItem('acl');
						
						// clear the old user if exists
						state.user = null;
						localStorage.removeItem('user');
						
						// remove also env data from a previous user
						localStorage.removeItem('env');
						
						// set new token
						localStorage.setItem('token', JSON.stringify(tempToken));
						state.token = JSON.parse(localStorage.getItem('token'));
						
						setTimeout(function (){
							// delete cookies
							// wait to avoid race condition
							Vue.$cookies.remove('access_token' , '/', `${process.env.VUE_APP_M360_DOMAIN}`);
							Vue.$cookies.remove('refresh_token' , '/', `${process.env.VUE_APP_M360_DOMAIN}`);
						}, 5000);
					}
				}
			}
			
			if (document.cookie) {
				loadCookie();
			}
			
			if(localStorage.getItem('user')){
				state.user = JSON.parse(localStorage.getItem('user'));
			}
			if(localStorage.getItem('acl')){
				state.acl = JSON.parse(localStorage.getItem('acl'));
			}
			state.loaded = true;
		},
	},
	
	actions: {
		setACL({commit}, value) {
			commit('setACL', value);
		},
		
		setUser({commit}, value) {
			commit('setUser', value);
		},
		
		removeENVACL({commit}, value) {
			commit('removeENVACL', value);
		},
		
		setUserAndACL({commit}, value) {
			commit('setUser', value);
			commit('setACL', value);
		},
		
		clearUserAndACL({commit}) {
			commit('clearUser', null);
			commit('clearACL', null);
		},
		
		setToken({commit}, value) {
			commit('setToken', value);
		},
		
		clear({commit}) {
			commit('setToken', null);
			commit('clearUser', null);
			commit('clearACL', null);
		},
		
		reloadState({commit, state}, force = false) {
			if (!state.loaded || force) commit('reloadState');
		},
	},
	
	getters: {
		getToken: state => state.token,
		getUser: state => state.user,
		getACL: state => state.acl,
	}
}
