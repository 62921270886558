/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@/sass/_text.scss'
import '@/sass/json-viewer.scss'

Vue.use(Vuetify)

const theme = {
  primary: '#18b3b3', // '#e91e63'
  secondary: '#1867c0',
  accent: '#9C27b0',
  info: '#00CAE3',
  muted: '#6c757d'
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
})
