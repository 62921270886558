/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue           from 'vue'
import App           from './App.vue'
import router        from './router'
import store         from './store/store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import './plugins/vue-world-map'
import vuetify       from './plugins/vuetify'
import i18n          from './i18n'
import AsyncComputed from 'vue-async-computed';

Vue.use( AsyncComputed );

import VueCookies from 'vue-cookies';

Vue.use( VueCookies );

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

import VueLodash from 'vue-lodash'
import lodash    from 'lodash'

Vue.use( VueLodash, { name: '_lodash', lodash: lodash } );

import axios     from 'axios'
import acl       from "@/acl";
import helpLinks from "@/config/helpMenu";

let M360BaseUrl = process.env.VUE_APP_M360_GATEWAY;
axios.get( "/settings.json" )
     .then( ( response ) => {
	     if ( response && response.data && response.data.M360Gateway && response.data.M360Gateway.trim() !== '' ) {
		     M360BaseUrl = response.data.M360Gateway;
	     }
     } )
	.finally(() => {
		const base = axios.create( {
			                           baseURL: M360BaseUrl,
			                           headers: { 'Content-Type': 'application/json' }
		                           } );
		Vue.prototype.$http = base;
		
		Vue.prototype.$acl = acl;
		
		Vue.prototype.$helpLinks = helpLinks;
		
		Vue.config.productionTip = false;
		
		new Vue( {
			         router,
			         store,
			         vuetify,
			         i18n,
			         render: h => h( App ),
			         beforeCreate() {
				         this.$store.dispatch( 'init' );
			         }
		         } ).$mount( '#app' );
		
		Vue.directive( 'init', {
			bind: function ( el, binding, vnode ) {
				if ( vnode.context[ binding.expression ] && typeof vnode.context[ binding.expression ] === 'function' ) {
					vnode.context[ binding.expression ]();
				}
			}
		} );
	});