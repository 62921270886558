/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
  namespaced: true,
  state: {
    env: null
  },

  //mutations are the recommended way to change the state in a vue app
  mutations: {
    setEnv(state, env) {
      state.env = env;
      localStorage.setItem('env', JSON.stringify({
        name: env.name,
        value: env.value,
        color: env.color
      }));
    },
    clearEnv(state) {
      state.env = null;
      localStorage.removeItem('env');
    }
  },

  //actions are used to communicate with remote apis and fetch data async
  actions: {
    set({commit}, env) {
      commit('setEnv', env);
    },
    clear({commit}) {
      commit("clearEnv");
    }
  },

  //getters are helpers to retrieve data from the state in a vue app
  getters: {
    get(state) {
      if(localStorage.getItem('env')){
        state.env = JSON.parse(localStorage.getItem('env'));
      }
      return state.env;
    }
  }
}
