/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

import Vue from 'vue'
import Vuex from 'vuex'

import envModule from './modules/env';
import authModule from './modules/auth';
import projectModule from './modules/project';
import messageModule from './modules/messages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    portal: false,
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    // barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SCRIM (state, payload) {
      state.barColor = payload
    },

    //set or clear tokens
    setPortalEnv(state, value) {
      if (value) {
        state.portal = value;
        localStorage.setItem('portal', true);
      } else {
        state.portal = null;
        localStorage.removeItem('portal');
      }
    }
  },
  actions: {
    // called at boot to reload state from localstorage
    init(store, force = false) {
      try {
        store.dispatch('auth/reloadState', force);
      } catch (e) {
        console.error(e);
        store.dispatch('clear');
      }
    },
    enablePortal({commit}) {
      commit('setPortalEnv', true);
    },
    disablePortal({commit}) {
      commit('setPortalEnv', false);
    },
  },

  getters: {
    getPortal: state => {
      if (localStorage.getItem('portal')) {
        state.portal = localStorage.getItem('portal');
      } else if (Object.hasOwnProperty.call(state, 'portal')) {
        localStorage.setItem('portal', state.portal);
      }
      return state.portal;
    }
  },

  modules: {
    env: envModule,
    auth: authModule,
    project: projectModule,
    globalMessages: messageModule,
  }
})
