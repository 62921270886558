/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

export default {
  namespaced: true,

  state: {
    project: null
  },

  mutations: {

    //set or clear user
    set(state, value) {
      if (value) {
        state.project = JSON.parse(JSON.stringify(value));
        localStorage.setItem('project', state.project);
      }
    },

    clear(state) {
      state.project = null;
      localStorage.removeItem('project');
    }
  },

  actions: {

    set({commit}, value) {
      commit('set', value);
    },

    clear({commit}) {
      commit('clear', null);
    }
    
  },

  getters: {
    get: state => {
      if (localStorage.getItem('project')) {
        state.project = localStorage.getItem('project');
      } else if (state.project) {
        localStorage.setItem('project', state.project);
      }
      
      return state.project;
    }
  }
}
