/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 *  Unauthorized copying of this file, via any medium is strictly prohibited.
 *  Proprietary and confidential.
 */

let routes = [
	// ======================
	// Blank Layout
	// ======================
	{
		path: '',
		component: () => import('@/views/external/Index'),
		// ======================
		// Theme routes / pages
		// ======================
		children: [
			{
				path: '/',
				redirect: '/login'
			},
			{
				path: '/login',
				name: 'Login',
				index: 1,
				component: () => import('@/views/external/Login'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/otp',
				name: 'Submit OTP',
				component: () => import('@/views/external/Otp'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/forgot-password',
				name: 'Forgot Password',
				component: () => import('@/views/external/ForgotPassword'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/reset-password/:token',
				name: 'Reset Password',
				props: true,
				component: () => import('@/views/external/ResetPassword'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/verify-email/:token',
				name: 'Verify Email',
				props: true,
				component: () => import('@/views/external/VerifyEmail'),
				meta: {
					acl: 'public'
				}
			},
			{
				path: '/logout',
				name: 'Logout',
				index: 2,
				meta: {
					acl: 'private'
				}
			},
			{
				path: '/error404',
				name: 'error404',
				index: 6,
				component: () => import('@/views/external/Error'),
				meta: {
					acl: '*'
				}
			}
		]
	},
	{
		path: '/',
		component: () => import('@/views/internal/Index'),
		meta: {
			acl: 'private'
		},
		children: [
			// Components
			// Dashboard
			{
				name: 'Dashboard',
				path: '/:envCode',
				meta: {
					section: {name: 'environment', default: true},
					acl: 'private',
					breadcrumb: [
						{
							title: 'Dashboard',
							disabled: false
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/Dashboard'),
			},
			{
				name: 'Profile',
				path: 'organization/account/:envCode',
				meta: {
					section: {name: 'organization', default: true},
					acl: {
						envCode: 'portal', //force this env code for this module, module acl always checks access with this code
						method: 'get',
						route: '/organization/users/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Profile',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/Profile'),
			},
			{
				name: 'Accounts',
				path: 'organization/accounts/:envCode',
				meta: {
					section: {name: 'organization'},
					acl: {
						envCode: 'portal',
						method: 'get',
						route: '/organization/accounts',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Accounts',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/Accounts'),
			},
			{
				name: 'addAccount',
				path: 'organization/accounts/:envCode/new',
				meta: {
					section: {name: 'organization'},
					acl: {
						envCode: 'portal',
						method: 'put',
						route: '/organization/accounts',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Accounts',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/AccountsAdd'),
			},
			{
				name: 'editAccount',
				path: 'organization/accounts/:envCode/:id',
				meta: {
					section: {name: 'organization'},
					acl: {
						envCode: 'portal',
						method: 'patch',
						route: '/organization/accounts/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Accounts',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/AccountsEdit'),
			},
			{
				name: 'Users',
				path: 'organization/users/:envCode',
				meta: {
					section: {name: 'organization'},
					acl: {
						envCode: 'portal',
						method: 'get',
						route: '/organization/users',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/Users'),
			},
			{
				name: 'addUser',
				path: 'organization/users/:envCode/new',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'put',
						route: '/organization/users',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							href: 'Users'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/UsersAdd')
			},
			{
				name: 'editUser',
				path: 'organization/users/:envCode/:id',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'patch',
						route: '/organization/users/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Users',
							href: 'Users'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/UsersEdit')
			},
			{
				name: 'Groups',
				path: 'organization/groups/:envCode',
				meta: {
					section: {name: 'organization'},
					acl: {
						envCode: 'portal',
						method: 'get',
						route: '/organization/groups',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Groups',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/Groups'),
			},
			{
				name: 'addGroup',
				path: 'organization/groups/:envCode/new',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'put',
						route: '/organization/groups',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Groups',
							href: 'Groups'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/GroupsAddEdit')
			},
			{
				name: 'editGroup',
				path: 'organization/groups/:envCode/:id',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'patch',
						route: '/organization/groups/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Groups',
							href: 'Groups'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/GroupsAddEdit')
			},
			{
				name: 'Roles',
				path: 'organization/roles/:envCode',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'get',
						route: '/environments/:env/roles',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Permissions',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/Roles'),
			},
			{
				name: 'addRole',
				path: 'organization/roles/:envCode/new',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'put',
						route: '/environments/:env/roles',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Permissions',
							href: 'Roles'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/RolesAddEdit')
			},
			{
				name: 'editRole',
				path: 'organization/roles/:envCode/:id',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'patch',
						route: '/environments/:env/roles/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Permissions',
							href: 'Roles'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/RolesAddEdit')
			},
			{
				name: 'editRoleByCode',
				path: 'organization/roles/:envCode/code/:code',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'patch',
						route: '/environments/:env/roles/:id',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Permissions',
							href: 'Roles'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/RolesAddEdit')
			},
			{
				name: 'editRolesService',
				path: 'organization/roles/:envCode/:id/:service',
				meta: {
					section: {name: 'organization'},
					acl: {
						method: 'patch',
						route: '/environments/:env/roles/:id/services/:service',
					},
					breadcrumb: [
						{
							title: 'Organization',
							disabled: true
						},
						{
							title: 'Permissions',
							href: 'editRole'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/users/RolesServiceAddEdit')
			},
			
			// environment modules
			{
				name: 'createEnvironment',
				path: '/environment/new',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'put',
						route: '/environments'
					},
					breadcrumb: [
						{
							title: 'Environments',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/environment/Index'),
			},
			{
				name: 'Settings',
				path: '/settings/:envCode',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'get',
						route: '/environments/:env/registry'
					},
					breadcrumb: [
						{
							title: 'Settings',
							disabled: true
						},
						{
							title: 'Registry',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/settings/Index'),
			},
			{
				name: 'createCustomRegistry',
				path: '/custom-entries/:envCode/new/:type',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'put',
						route: '/environments/:env/custom-entries'
					},
					breadcrumb: [
						{
							title: 'Settings',
							href: 'Settings'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/settings/CustomAddEdit'),
			},
			{
				name: 'editCustomRegistry',
				path: '/custom-entries/:envCode/new/:type/:id',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/environments/:env/custom-entries/:id'
					},
					breadcrumb: [
						{
							title: 'Settings',
							href: 'Settings'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/settings/CustomAddEdit'),
			},
			{
				name: 'Resources',
				path: '/resources/:envCode',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'get',
						route: '/environments/:env/data-clusters'
					},
					breadcrumb: [
						{
							title: 'Resources',
							disabled: true
						},
						{
							title: 'Data Clusters',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/resources/Index'),
			},
			{
				name: 'createResource',
				path: '/resources/:envCode/new',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'put',
						route: '/environments/:env/data-clusters'
					},
					parent: 'Resources',
					breadcrumb: [
						{
							title: 'Resources',
							disabled: true
						},
						{
							title: 'Data Clusters',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/resources/ResourcesAddEdit'),
			},
			{
				name: 'editResource',
				path: '/resources/:envCode/:id',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/environments/:env/data-clusters/:id'
					},
					parent: 'Resources',
					breadcrumb: [
						{
							title: 'Resources',
							disabled: true
						},
						{
							title: 'Data Clusters',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/resources/ResourcesAddEdit'),
			},
			{
				name: 'createDatabase',
				path: '/databases/:envCode/new',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'put',
						route: '/environments/:env/databases'
					},
					parent: 'Resources',
					breadcrumb: [
						{
							title: 'Resources',
							disabled: true
						},
						{
							title: 'Data Clusters',
							disabled: true
						},
						{
							title: 'Database',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/resources/DatabasesAddEdit'),
			},
			{
				name: 'editDatabase',
				path: '/databases/:envCode/:id',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/environments/:env/databases/:name'
					},
					parent: 'Resources',
					breadcrumb: [
						{
							title: 'Resources',
							disabled: true
						},
						{
							title: 'Data Clusters',
							disabled: true
						},
						{
							title: 'Database',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/resources/DatabasesAddEdit'),
			},
			{
				name: 'Multi Tenancy',
				path: '/tenants/:envCode',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'get',
						route: '/environments/:env/:type/tenants'
					},
					breadcrumb: [
						{
							title: 'Tenant Applications',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/tenants/Index'),
			},
			{
				name: 'createTenant',
				path: '/tenants/:envCode/new',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'put',
						route: '/environments/:env/tenants'
					},
					parent: 'Multi Tenancy',
					breadcrumb: [
						{
							title: 'Tenant Applications',
							href: 'Multi Tenancy'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/tenants/addTenant'),
			},
			{
				name: 'editTenantConfig',
				path: '/tenants/:envCode/edit/:id',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/environments/:env/tenants/:id'
					},
					parent: 'Multi Tenancy',
					breadcrumb: [
						{
							title: 'Tenant Applications',
							href: 'Multi Tenancy'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/tenants/editTenant'),
			},
			{
				name: 'editUserTenantConfig',
				path: '/tenants/:envCode/edit/:id/user/:user',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/organization/users/:id'
					},
					breadcrumb: [
						{
							title: 'Tenant Applications',
							href: 'Multi Tenancy'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/tenants/editTenant'),
			},
			{
				name: 'editAccountTenantConfig',
				path: '/tenants/:envCode/edit/:id/account/:account',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'patch',
						route: '/organization/accounts/:id'
					},
					breadcrumb: [
						{
							title: 'Tenant Applications',
							href: 'Multi Tenancy'
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/tenants/editTenant'),
			},
			{
				name: 'API Catalog',
				path: '/api-catalog/:envCode',
				meta: {
					section: {name: 'environment'},
					acl: {
						method: 'get',
						route: '/environments/:env/api-catalog'
					},
					breadcrumb: [
						{
							title: 'API Catalog',
							disabled: true
						}
					]
				},
				props: true,
				component: () => import('@/views/internal/pages/api_catalog/Index'),
			},
		],
	},
	{
		path: '*',
		component: () => import('@/views/external/Index'),
		children: [
			{
				name: '404 Error',
				path: '',
				component: () => import('@/views/external/Error'),
			},
		],
	},
];

module.exports = routes;
